<route>
{
  "meta": {
    "auth": "personEdit"
  }
}
</route>

<template>
  <el-dialog :close-on-click-modal="false" :visible.sync="visible" title="编辑角色" class="person-conf">
    <el-form label-width="100px" label-position="right">
      <el-form-item label="人员ID">
        <div class="value">
          {{ data.memberId }}
          <div class="user">
            <img :src="data.avatarUrl | thumbSmall" />
            {{ data.wxNickname }}
          </div>
        </div>
      </el-form-item>
      <div class="title">
        绑定信息
      </div>

      <el-form-item label="联系方式">
        {{ data.phoneNumber }}
      </el-form-item>
      <el-form-item label="人员角色">
        <el-radio-group v-model="roleId">
          <el-radio disabled v-for="(item, index) in role.list" :key="index" :label="item.id">{{
            item.roleName
          }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <div class="title">拥有的权限</div>
      <div class="permission">
        <div class="item" v-for="(item, index) in rolePrivilegelist" :key="index">
          <i class="el-icon-circle-check icon"></i>
          {{ item.privilegeName }}
        </div>
      </div>
      <div class="title">消息通知</div>
      <div class="permission">
        <div class="item" v-for="(item, index) in informPrivilegelist" :key="index">
          <i class="el-icon-circle-check icon"></i>
          {{ item.privilegeName }}
        </div>
      </div>
      <el-form-item label="绑定商家下门店">
        <el-radio @change="handleChange" v-model="allStorePrivilege" :label="1">全部门店</el-radio>
        <el-radio @change="handleChange" v-model="allStorePrivilege" :label="0">部分门店</el-radio>
      </el-form-item>
      <el-form-item label="所属门店">
        <el-checkbox-group v-model="checkedStore">
          <el-checkbox v-for="(item, index) in store.list" :disabled="isDisabled" :label="item.id" :key="index">{{
            item.storeTitle
          }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
    </el-form>

    <div slot="footer" class="footer">
      <el-button round @click="visible = false" class="btn outline">取消</el-button>
      <el-button round type="primary" @click="submit" class="btn">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: {}
    },
    store: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      allStorePrivilege: 0,
      isDisabled: false,
      phone: '',
      checkedStore: [],
      visible: this.show,
      roleId: '',
      role: {
        list: []
      },
      informPrivilegelist: [],
      rolePrivilegelist: []
    }
  },
  watch: {
    visible(v) {
      if (!v) this.$emit('close')
    },
    show(v) {
      this.visible = v
    },
    data(n, o) {
      this.roleId = n.roleId
      this.allStorePrivilege = n.allStorePrivilege
      if (n.allStorePrivilege) {
        this.checkedStore = this.store.list.map(item => {
          return item.id
        })
      } else {
        this.checkedStore = n.storeIdList
      }

      this.role.list.forEach(item => {
        if (item.id === n.roleId) {
          this.informPrivilegelist = item.informPrivilegelist
          this.rolePrivilegelist = item.rolePrivilegelist
        }
      })
    }
  },
  created() {
    this.getRole()
  },
  methods: {
    submit() {
      let params = {
        avatarUrl: this.data.avatarUrl,
        businessId: this.$store.getters['vendor/params/getVendorId'],
        id: this.data.id,
        memberId: this.data.memberId,
        phoneNumber: this.data.phoneNumber,
        realName: this.data.realName,
        roleId: this.roleId,
        storeIdList: this.checkedStore,
        subMerchantId: this.data.subMerchantId,
        wxNickname: this.data.wxNickname,
        wxOpenId: this.data.wxOpenId,
        allStorePrivilege: this.allStorePrivilege
      }
      const loading = this.$loading({
        lock: true,
        text: '正在提交，请稍候',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$api.vendor
        .vendor('putBusinessUser', params)
        .then(res => {
          this.$emit('close')
          this.$message.success('修改成功')
          this.$emit('getList')
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          loading.close()
        })
    },
    handleChange(val) {
      this.checkedStore = []
      this.isDisabled = false
      if (val == 1) {
        this.isDisabled = true
        this.checkedStore = this.store.list.map(item => {
          return item.id
        })
      }
    },
    // 获取角色列表

    getDefaultRole() {
      return this.$http
        .get('/boom-center-admin-service/sysAdmin/businessRole/business/default/roleList', {
          params: {
            size: 1000
          }
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    },
    getCurrentBusinessRole() {
      return this.$api.vendor
        .vendor('getStoreRoleList', {
          params: { businessId: this.$store.getters['vendor/params/getVendorId'], size: 1000 }
        })

        .catch(err => {
          this.$message.error(err.msg)
        })
    },
    getRole() {
      Promise.all([this.getDefaultRole(), this.getCurrentBusinessRole()]).then(res => {
        console.log(res)
        this.form = { ...res }
        this.role.list.push(...res[0].list, ...res[1].list)
        this.roleId = this.role.list[0].id
      })
    }
  }
}
</script>

<style scoped lang="less">
.person-conf {
  .value {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    .user {
      display: flex;
      overflow: hidden;
      align-items: center;
      border: 1px solid #eeeeee;
      border-radius: 10px;
      padding: 6px 12px;
      width: fit-content;
      cursor: pointer;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
        margin-right: 10px;
      }
    }
  }
  .permission {
    margin-bottom: 30px;
    .item {
      display: inline-flex;
      margin-right: 15px;
      .icon {
        color: #e7141a;
      }
    }
  }
  .title {
    font-size: 14px;
    font-weight: bold;
    color: black;
    margin-bottom: 20px;
  }

  .action {
    margin-top: 30px;
    .btn {
      border-radius: 30px;
    }
    .outline {
      background: #fff;
      color: #e7141a;
    }
  }
}
/deep/ .el-dialog {
  padding: 30px;
  border-radius: 20px;
}
</style>
