<route>
{
  "meta": {
    "auth": "personManage"
  }
}
</route>

<template>
  <div>
    <bm-cardTabList :list="tabList" current="人员管理"></bm-cardTabList>
    <div class="tableContainer">
      <div class="header" style="margin: 16px 0">
        <span class="title">人员列表</span>
        <el-button style="float: right" type="success" @click="dialogVisible = true">添加</el-button>
      </div>
      <el-table :data="list" v-loading="loading" style="width: 100%">
        <el-table-column align="center" prop="memberId" label="人员ID"> </el-table-column>
        <el-table-column align="center" prop="wxNickname" label="人员昵称"> </el-table-column>
        <el-table-column align="center" label="头像">
          <template slot-scope="scope">
            <img class="avatar" :src="scope.row.avatarUrl | thumbSmall" />
          </template>
        </el-table-column>
        <el-table-column align="center" prop="phoneNumber" label="电话号码"> </el-table-column>
        <el-table-column align="center" label="所属门店">
          <template slot-scope="scope">
            {{ scope.row.allStorePrivilege ? '所有门店' : scope.row.storeNames }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="roleName" label="人员角色"> </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button @click="edit(scope.row)" type="text" size="small">编辑</el-button>
            <el-button type="text" size="small" class="deleteBtnText" @click="showDelete(scope)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-pagination
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          class="pagination"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 删除组件 -->
    <bm-deleteItem
      url="boom-center-admin-service/sysAdmin/businessUser/"
      :timestamp="timestamp"
      @successDelete="successDelete"
    ></bm-deleteItem>
    <personConf
      :show.sync="confDialog.show"
      @getList="getList"
      :store="store"
      :data="confDialog.data"
      @close="confDialog.show = false"
    />
    <personAdd @getList="getList" :store="store" :show.sync="dialogVisible" @close="closeDialog" />
  </div>
</template>

<script>
import personConf from './personConf'
import personAdd from './personAdd'
import { pagination } from '@/mixin/pagination'
import { deleteRowItem } from '@/mixin/deleteRowItem'
import { tabListIncludeAccount, tabListExcludeAccount } from '../tabList'
import { mapGetters } from 'vuex'

export default {
  mixins: [pagination, deleteRowItem],
  components: { personConf, personAdd },
  data() {
    return {
      timestamp: 0,
      dialogVisible: false,
      confDialog: {
        show: false,
        data: {}
      },
      list: [],
      tabList: [],
      timestamp: 0,
      store: {
        list: [],
        selected: 0
      }
    }
  },
  computed: {
    ...mapGetters({ business: 'business/currentBusiness' })
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      console.log(vm)
      if (vm.$store.getters['vendor/params/getBusinessModel'] == 0) {
        vm.tabList = tabListIncludeAccount
      } else {
        vm.tabList = tabListExcludeAccount
      }
    })
  },
  created() {
    if (this.business.name) {
      sessionStorage.setItem('currentBusiness', this.business.name)
    }
    this.getStore()
  },
  beforeDestroy() {
    sessionStorage.removeItem('currentBusiness')
  },
  methods: {
    // 获取门店列表
    getStore() {
      this.$api.vendor
        .vendor('storeListHasPerson', {
          params: {
            businessId: this.$store.getters['vendor/params/getVendorId'],
            size: 1000
          }
        })
        .then(res => {
          this.store.list = res.list
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    },
    closeDialog(val) {
      this.dialogVisible = false
    },
    edit(row) {
      this.confDialog.show = true
      this.$api.vendor
        .vendor('getBusinessUserDetail', row.id)
        .then(res => {
          this.confDialog.data = res
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    },

    getList(val) {
      this.loading = true
      this.currentPage = val === true ? this.currentPage : 1
      let params = {
        params: {
          businessId: this.$store.getters['vendor/params/getVendorId'],
          page: this.currentPage,
          size: this.pageSize
        }
      }
      this.$api.vendor
        .vendor('getBusinessUser', params, this.$store.getters['vendor/params/getVendorId'])
        .then(res => {
          this.list = res.list
          this.total = res.total
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="less" scoped>
.tableContainer {
  background-color: #fff;
  padding: @container-margin;
}
.searchContainer {
  display: flex;
  margin: @container-margin;

  .searchInput {
    width: 300px;
    margin-right: 10px;
  }
}
.avatar {
  width: 50px;
  height: 50px;
}
</style>
